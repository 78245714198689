<template>
  <div class="contents" v-loading="loading">
    <div class="title flexB">
      <h2>문장 관리</h2>
    </div>
    <div class="box one filter">
      <div class="flex">
        <p class="bold">검색조건</p>
        <select v-model="inputMode">
          <option value="" selected disabled>선택</option>
          <option value="sentence">단어</option>
        </select>
        <input type="text" v-model="searchWord" />
      </div>
      <div class="buttonWrap">
        <button class="basic" @click="search">검색</button>
        <button class="line" @click="reset">초기화</button>
      </div>
    </div>
    <div class="box one">
      <h2 class="bold">
        문장 리스트<button class="right basic">
          <router-link to="/admin/sentence/register">문장 등록</router-link>
        </button>
      </h2>
      <table>
        <colgroup>
          <col style="width: 7%" />
          <col style="width: 15%" />
          <col style="width: 7%" />
          <col style="width: 10%" />
          <col style="width: 25%" />
          <col style="width: 25%" />
          <col style="width: 15%" />
        </colgroup>
        <tr>
          <th>번호</th>
          <th>카테고리</th>
          <th>레벨</th>
          <th>단어</th>
          <th>문장</th>
          <th>영문</th>
          <th>등록일</th>
        </tr>
        <tr v-for="(data, i) in sentenceList" :key="i">
          <td>{{ sentenceList.length - i }}</td>
          <td>{{ data.category }}</td>
          <td>{{ data.level }}</td>
          <td>
            <router-link to @click.native="handleMove(data._id)">{{
              data.word
            }}</router-link>
          </td>
          <td>
            {{ data.phrase_front }} {{ data.word }} {{ data.phrase_back }}
          </td>
          <td>{{ data.description_en }}</td>
          <td>{{ moment(data.createdAt).format("YYYY.MM.DD HH:mm:ss") }}</td>
        </tr>
      </table>
      <div class="pagination" v-if="sentenceList.length > 0">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="10"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
      <NoData v-else />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { fetchSentenceList } from "@/api/admin";
import { format } from "@/mixins/format";
import NoData from "@/components/contents/Nodata";
export default {
  mixins: [format],
  components: { NoData },
  name: "sentenceManage",
  data() {
    return {
      moment: moment,
      loading: false,
      inputMode: "",
      searchWord: "",
      sentenceList: [],
      total: 0,
      currentPage: 1,
      isNodata: false,
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "3");
  },
  mounted() {
    this.loading = true;
    this.getSentenceList();
  },
  methods: {
    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },
    handleMove(id) {
      this.$router.push({ name: "sentenceRegister", query: { id: id } });
    },

    reset() {
      this.inputMode = "";
      this.searchWord = "";
      this.sentenceList = [];
      this.total = 0;
      this.currentPage = 1;
      this.getSentenceList();
    },
    search() {
      if (this.searchWord && this.inputMode == "") {
        return alert("검색 조건을 선택해 주세요.");
      }
      this.getSentenceList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getSentenceList();
    },
    getSentenceList() {
      let params = {
        page: this.currentPage,
        searchWord: this.searchWord,
        inputMode: this.inputMode,
      };
      fetchSentenceList(params).then((res) => {
        console.log("sentence >>>", res);
        if (res.data.status == 200) {
          this.sentenceList = res.data.data;
          this.total = res.data.total;
          this.isNodata = this.total == 0 ? true : false;
          this.loading = false;
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
  },
};
</script>
